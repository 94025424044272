import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { FaGithub, FaLinkedin, FaEnvelope, FaTwitter, FaInstagram, FaDownload } from "react-icons/fa"
import siteConfig from '../../data/siteConfig'

import Layout from '../components/layout'
import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'
import About from '../components/about'
import Skills from '../components/skills'
import Timeline from '../components/timeline'

const Separator = styled.hr`
  background-color: #aaa;
  margin-top: 24px;
  margin-bottom: 16px;
`


const Home = props => {
    const {cover} = useStaticQuery(graphql`
      query {
        cover: file(relativePath: { eq: "cover.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `);

    // validate siteConfig settings
    if (siteConfig.googleAnalyticsId === 'UA-000000000-1') {
      console.error('WARNING: Please set a proper googleAnalyticsId. See https://analytics.google.com for details.');
    }

    const title = siteConfig.siteTitle
    const avatar = siteConfig.authorAvatar
    const {keywords} = siteConfig


    return (
      <Layout location={props.location}>
        <SEO
          title={title}
          keywords={keywords}
        />

        <Hero
          coverImg={cover.childImageSharp.fluid}
          title={title}
          subtitle={siteConfig.siteSubtitle}
        />

        <Wrapper className={props.className} >
          <Container className="page-content" fluid>
            <Row>
              <Col xs={4} className='avatar'>
                <picture>
                  <source srcSet={`${avatar}.webp`} type='image/webp' />
                  <source srcSet={`${avatar}.jpeg`} type='image/jpeg' />
                  <img
                    className='avatar__image'
                    src={`${avatar}.png`}
                    alt='Manuel Soldini'
                  />
                </picture>
                <div className="social">
                  {siteConfig.social.cv && <a aria-label='Download CV' className="social-link resume" href={siteConfig.social.cv} download>
                    <FaDownload className="social-icon" size="32" />
                  </a>}
                  {siteConfig.social.instagram && <a aria-label='IG Link' className="social-link instagram" href={siteConfig.social.instagram}>
                    <FaInstagram className="social-icon" size="32" />
                  </a>}
                  {siteConfig.social.linkedin && <a aria-label='LinkedIn Profile' className="social-link linkedin" href={siteConfig.social.linkedin}>
                    <FaLinkedin className="social-icon" size="32" />
                  </a>}
                  {siteConfig.social.github && <a aria-label='Github Profile' className="social-link github" href={siteConfig.social.github}>
                    <FaGithub className="social-icon" size="32" />
                  </a>}
                  {siteConfig.social.twitter && <a aria-label='Twitter Profile' className="social-link twitter" href={siteConfig.social.twitter}>
                    <FaTwitter className="social-icon" size="32" />
                  </a>}
                  {siteConfig.social.email && <a aria-label='Send email' className="social-link email" href={`mailto:${siteConfig.social.email}`}>
                    <FaEnvelope className="social-icon" size="32" />
                  </a>}
                </div>
              </Col>
            </Row>
	        <Row>
              <Col xs={4} sm={4}>
                <About title='About' text={siteConfig.authorDescription}/>
              </Col>
              <Col xs={4} sm={4}>
                <Skills title='Skills' skills={siteConfig.skills} />
              </Col>
            </Row>

            <Separator />
            <Timeline />
            <Separator />
            <About title='Hobbies' text={siteConfig.authorMisc}/>
          </Container>
        </Wrapper>
      </Layout>
    )

}

export default styled(Home)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }
  .avatar {
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
  }
  .avatar__image {
    box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.75);
    max-width: 200px;
    border-radius: 100px;
    margin: 0 auto 24px;
  }

  .social-icon {
    color: #999;
  }

  .social {
    margin-top: 12px;
    margin-bottom: 12px;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: row;
    min-width: 240px;
  }

  .social-link {
    color: #555;
  }

  a.social-link.resume {
    padding-left: 0;
  }
  a.social-link.email {
    padding-right: 0;
  }

  a.social-link.twitter:hover .social-icon{
    color: #1da1f2;
  }

  a.social-link.instagram:hover .social-icon{
    color: #f21d64;
  }

  a.social-link.github:hover .social-icon{
    color: #dbd6d1;
  }

  a.social-link.linkedin:hover .social-icon{
    color: #0077B5;
  }

  a.social-link.email:hover .social-icon{
    color: #c23a2b;
  }

  a.social-link.resume:hover .social-icon{
    color: #ff7733;
  }
`
